import { ReactNode } from "react";
import "./Dialog.scss";

interface DialogProps {
    className?:string;
    children: ReactNode;
}

const Dialog = (props: DialogProps) => {
    return (
        <>
            <div className="dialog-background">
            </div>
            <div className={`dialog ${props.className}`}>
                {props.children}
            </div>
        </>
    )
}

export default Dialog;
import { Dispatch, SetStateAction, createContext } from "react";
import { GiftsApi, IAccountData } from "../../services/GiftsApi";
import IConfig from "../../IConfig";

export interface IGlobalVariablesContextData {
    config: IConfig;
    setConfig: Dispatch<SetStateAction<IConfig>>;
    giftsApi: GiftsApi;
    setGiftsApi: Dispatch<SetStateAction<GiftsApi>>;
    account: IAccountData;
    setAccount: Dispatch<SetStateAction<IAccountData>>;
};

export const GlobalVariablesContext = createContext<IGlobalVariablesContextData>({
    config: null,
    setConfig: null,
    giftsApi: null,
    setGiftsApi: null,
    account: null,
    setAccount: null
});

export default GlobalVariablesContext;
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAccount } from '../../../store/accountSlice';
import Avatar from '../../atoms/Avatar/Avatar';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import Spinner from '../../atoms/Spinner/Spinner';
import WideButton from '../../atoms/WideButton/WideButton';
import './LoginForm.scss';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';

const LoginForm = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [authenticating, setAuthenticating] = useState(false);

    const [userName, setUserName] = useState('');

    const [password, setPassword] = useState('');

    const [error, setError] = useState('');

    const navigate = useNavigate();

    const [getParameters] = useSearchParams();

    const dispatch = useDispatch();

    const handleLoginButtonClick = async () => {
        setAuthenticating(true);
        setError('');
        await giftsApi
            .login({
                userName: userName,
                password: password
            })
            .then(data => {
                let returnUrl = getParameters.get('returnUrl');
                if (returnUrl !== null && returnUrl.startsWith('/gifts/')) {
                    returnUrl = returnUrl.substring(7);
                }
                dispatch(setAccount(data.data));
                navigate(returnUrl !== null && returnUrl !== '' ? returnUrl : '/home');
            })
            .catch(error => {
                setAuthenticating(false);
                setError(error.response.data.error);
            });
    }

    return (
        <form method="post">

            <div className="login-avatar">
                <Avatar />
            </div>

            {error !== '' && <ErrorAlert title='Cannot Login' message={error} />}

            {authenticating
                ?
                <Spinner />
                :
                <>
                    <LabelledInput name="userName" label="Username" placeholder="Enter Username" required={true} onChange={e => setUserName(e.target.value)} />

                    <LabelledInput name="password" type="password" label="Password" placeholder="Enter Password" required={true} onChange={e => setPassword(e.target.value)} />

                    <WideButton label='Login' submits={true} onClick={() => handleLoginButtonClick()} />
                </>
            }

        </form>
    )
}

export default LoginForm;
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackLink from '../../atoms/BackLink/BackLink';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import WideButton from '../../atoms/WideButton/WideButton';
import ListVisibilitySelector from '../../organisms/ListVisibilitySelector/ListVisibilitySelector';
import { Visibility, IFriendData } from '../../../services/GiftsApi';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';

const CreateGiftListPage = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [listName, setListName] = useState('');

    const [visibility, setVisibility] = useState<Visibility>('friends');

    const [selectedFriends, setSelectedFriends] = useState<string[]>([]);

    const [error, setError] = useState('');

    let navigate = useNavigate();

    const [friends, setFriends] = useState<IFriendData[]>(null);

    const loadFriends = async () => {
        setFriends(null);
        await giftsApi
            .getFriends()
            .then(data => {
                setFriends(data.data);
            })
            .catch(error => {
            });
    }

    useEffect(() => {
        loadFriends();
    }, []);

    const handleCreateListButtonClick = async () => {
        await giftsApi
            .createList({
                name: listName,
                visibility: visibility,
                friends: selectedFriends
            })
            .then(data => {
                navigate('/lists/' + encodeURIComponent(data.data.id));
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    const handleVisibilityChanged = (visibility: Visibility, friends: string[]) => {
        setVisibility(visibility);
        setSelectedFriends(friends);
    }

    return (
        <>
            <BackLink />

            <h2>Create Gift List</h2>

            <form method="post">

                <LabelledInput name="listName" label="List Name" placeholder="Enter list name" required={true} onChange={e => setListName(e.target.value)} />

                <ListVisibilitySelector visibility={'friends'} friends={friends} selectedFriends={[]} onChange={(visibility, friends) => handleVisibilityChanged(visibility, friends)} />

                {error !== '' && <ErrorAlert title='Error Creating List' message={error} />}

                <WideButton label='Create' onClick={() => handleCreateListButtonClick()} />

            </form>

            <LinkBox href="/home" label="Cancel" />
        </>
    );
}

export default CreateGiftListPage;
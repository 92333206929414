import { Outlet } from "react-router-dom";
import Header from "../../organisms/Header/Header";
import "./Layout.scss"
import { useEffect, useContext } from "react";
import GlobalVariablesContext from "../../contexts/GlobalVariablesContext";
import { GiftsApi } from "../../../services/GiftsApi";
import IConfig from "../../../IConfig";

const Layout = () => {

    const { config, giftsApi, setConfig, setGiftsApi } = useContext(GlobalVariablesContext);

    const loadConfig = async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
            if (!response.ok) {
                throw new Error('Failed to fetch config');
            }
            const configData = await response.json() as IConfig;
            setConfig(configData);
            setGiftsApi(new GiftsApi(configData.giftsApiBaseUrl));
        } catch (error) {
            console.error('Error loading config:', error);
        }
    };

    useEffect(() => {
        loadConfig();
    }, []);

    return config && giftsApi && (
        <>
            <Header />
            <div className="container">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;

import { shortenUrl } from '../../../utils/stringUtils';
import './GiftLink.scss';

interface GiftLinkProps {
    title: string;
    url: string;
    shortenUrl: boolean;
}

const GiftLink = (props: GiftLinkProps) => {
    return (
        <>
            <span className="gift-link-title"><a href={props.url} target="_blank" rel="noreferrer">{props.title}</a></span><br />
            <span className="gift-link-url"><a href={props.url} target="_blank" rel="noreferrer">{props.shortenUrl ? shortenUrl(props.url) : props.url}</a></span>
        </>
    );
}

export default GiftLink;
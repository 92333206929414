import { useContext, useEffect, useState } from 'react';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import WideButton from '../../atoms/WideButton/WideButton';
import ListVisibilitySelector from '../ListVisibilitySelector/ListVisibilitySelector';
import { Visibility, IFriendData } from '../../../services/GiftsApi';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import Dialog from '../Dialog/Dialog';
import './ListDetailsDialog.scss';

interface ListDetailsDialogProps {
    name: string;
    visibility: Visibility;
    selectedFriends: string[];
    onSaved: (name: string, visibility: Visibility, friends: string[]) => void;
    onCancel: () => void;
}

const ListDetailsDialog = (props: ListDetailsDialogProps) => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [name, setName] = useState(props.name);

    const [visibility, setVisibility] = useState(props.visibility);

    const [friends, setFriends] = useState<IFriendData[]>(null);

    const [selectedFriends, setSelectedFriends] = useState<string[]>(props.selectedFriends);

    const loadFriends = async () => {
        setFriends(null);
        await giftsApi
            .getFriends()
            .then(data => {
                setFriends(data.data);
            })
            .catch(error => {
            });
    }

    useEffect(() => {
        loadFriends();
    }, []);

    const handleVisibilityChanged = (visibility: Visibility, friends: string[]) => {
        setVisibility(visibility);
        setSelectedFriends(friends);
    }

    const handleSaveButtonClick = () => {
        props.onSaved(name, visibility, selectedFriends);
    }

    const handleCancelButtonClick = () => {
        props.onCancel();
    }

    return (
        <Dialog className="list-details-dialog">
            <LabelledInput name="listName" label="List Name" placeholder="Enter list name" required={true} value={name} onChange={e => setName(e.target.value)} />
            <ListVisibilitySelector visibility={visibility} friends={friends} selectedFriends={selectedFriends} onChange={(visibility, friends) => handleVisibilityChanged(visibility, friends)} />
            <WideButton label='Save' onClick={() => handleSaveButtonClick()} />
            <WideButton label='Cancel' variant='red' onClick={() => handleCancelButtonClick()} />
        </Dialog>
    );
}

export default ListDetailsDialog;
import { useContext, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import InfoBox from '../../atoms/InfoBox/InfoBox';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import Spinner from '../../atoms/Spinner/Spinner';
import WideButton from '../../atoms/WideButton/WideButton';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';

const ForgotPasswordForm = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [resetting, setResetting] = useState(false);

    const [userName, setUserName] = useState('');

    const [emailAddress, setEmailAddress] = useState('');

    const [error, setError] = useState('');

    const [passwordReset, setPasswordReset] = useState(false);

    const handleResetButtonClick = async () => {
        setResetting(true);
        setError('');
        await giftsApi
            .forgotPassword({
                userName: userName,
                emailAddress: emailAddress
            })
            .then(data => {
                setResetting(false);
                setPasswordReset(true);
            })
            .catch(error => {
                setResetting(false);
                setError(error.response.data.error);
            });
    }

    return (
        <form method="post">

            {error !== '' && <ErrorAlert title='Cannot Reset Password' message={error} />}

            {resetting
                ? <Spinner />
                :
                <>
                    {passwordReset
                        ?
                        <>
                            <InfoBox message='An email has been sent to your email address with a link to reset your password.' />
                            <WideButton label='Go home' navigatesTo='/' />
                        </>
                        :
                        <>
                            <LabelledInput name="userName" label="Username" placeholder="Enter Username" required={true} onChange={e => setUserName(e.target.value)} />
                            <LabelledInput name="emailAddress" label="Email Address" placeholder="Enter Email Address" required={true} onChange={e => setEmailAddress(e.target.value)} />
                            <WideButton label='Reset Password' submits={true} onClick={() => handleResetButtonClick()} />
                        </>
                    }
                </>
            }
        </form>
    )
}

export default ForgotPasswordForm;
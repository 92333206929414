import { ReactNode, useMemo, useState } from "react";
import GlobalVariablesContext from "./GlobalVariablesContext";
import { GiftsApi, IAccountData } from "../../services/GiftsApi";
import IConfig from "../../IConfig";

export interface IGlobalVariablesContextProviderProps {
    children: ReactNode;
}

export const GlobalVariablesContextProvider = (props: IGlobalVariablesContextProviderProps) => {

    const [config, setConfig] = useState<IConfig>(null);

    const [giftsApi, setGiftsApi] = useState<GiftsApi>(null);

    const [account, setAccount] = useState<IAccountData>(null);

    const contextValue = useMemo(() => (
        { config: config, setConfig: setConfig, giftsApi: giftsApi, setGiftsApi: setGiftsApi, account: account, setAccount: setAccount }
    ), [config, giftsApi, account]);

    return <GlobalVariablesContext.Provider value={contextValue}>
        {props.children}
    </GlobalVariablesContext.Provider>;
};

export default GlobalVariablesContextProvider;
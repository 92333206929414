import { useContext, useState } from 'react';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import WideButton from '../../atoms/WideButton/WideButton';
import Dialog from '../Dialog/Dialog';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import Spinner from '../../atoms/Spinner/Spinner';
import './GiftLinkDialog.scss';

interface GiftLinkDialogProps {
    url?: string;
    title?: string;
    onSaved: (url: string, title: string) => void;
    onCancel: () => void;
}

const GiftLinkDialog = (props: GiftLinkDialogProps) => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [linkUrl, setLinkUrl] = useState(props.url);

    const [linkTitle, setLinkTitle] = useState(props.title);

    const [loadingTitle, setLoadingTitle] = useState(false);

    const isValidUrl = (url: string) => {
        let result = null as URL;
        try {
            result = new URL(url);
        } catch (_) {
            return false;
        }
        return result.protocol === "http:" || result.protocol === "https:";
    }

    const handleUrlChanged = async () => {
        if (!isValidUrl(linkUrl)) {
            return;
        }
        setLoadingTitle(true);
        await giftsApi
            .scrape(linkUrl)
            .then(data => {
                setLinkTitle(data.data.pageTitle);
            })
            .finally(() => {
                setLoadingTitle(false);
            });
    }

    const handleSaveButtonClick = () => {
        props.onSaved(linkUrl, linkTitle);
    }

    const handleCancelButtonClick = () => {
        props.onCancel();
    }

    return (
        <Dialog className="gift-link-dialog">
            <h1>Gift Link</h1>
            <LabelledInput name="linkUrl" label="Link URL" placeholder="Enter Website Address (URL)" required={true} value={linkUrl} onBlur={() => handleUrlChanged()} onChange={e => setLinkUrl(e.target.value)} />
            {loadingTitle &&
                <>
                    <p>Getting title...</p>
                    <Spinner />
                </>
            }
            {!loadingTitle &&
                <LabelledInput name="linkTitle" label="Link Title" placeholder="Enter Link Title" required={true} value={linkTitle} onChange={e => setLinkTitle(e.target.value)} />
            }
            <WideButton enabled={!loadingTitle} label="Save" onClick={() => handleSaveButtonClick()} />
            <WideButton label="Cancel" variant="red" onClick={() => handleCancelButtonClick()} />
        </Dialog>
    );
}

export default GiftLinkDialog;
import { useState } from 'react';
import { IAccountData } from '../services/GiftsApi';

const useAccount = (): IAccountData => {

    // TODO: Do we need this?
    
    const [id] = useState(localStorage.getItem('gifts.userId'));
    if (id === null) {
        return null;
    }

    const userName = localStorage.getItem('gifts.userName');
    const firstName = localStorage.getItem('gifts.firstName');
    const lastName = localStorage.getItem('gifts.lastName');

    return {
        id: id === null ? -1 : parseInt(id),
        userName: userName,
        firstName: firstName,
        lastName: lastName
    };
}

export default useAccount;
import { useContext, useEffect, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import FriendRequest from '../../organisms/FriendRequest/FriendRequest';
import UserFullName from '../../atoms/UserFullName/UserFullName';
import WideButton from '../../atoms/WideButton/WideButton';
import FriendSummary from '../../organisms/FriendSummary/FriendSummary';
import GiftListSummary from '../../organisms/GiftListSummary/GiftListSummary';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import Spinner from '../../atoms/Spinner/Spinner';
import ConfirmDialog from '../../organisms/ConfirmDialog/ConfirmDialog';
import { ISummaryListData, IFriendData, IFriendRequestsData } from '../../../services/GiftsApi';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import TabItem from '../../atoms/TabItem/TabItem';
import TabList from '../../organisms/TabList/TabList';
import './HomePage.scss';

const HomePage = () => {

  const { giftsApi } = useContext(GlobalVariablesContext);

  const [loadingLists, setLoadingLists] = useState(true);

  const [lists, setLists] = useState<ISummaryListData[]>(null);

  const [listsError, setListsError] = useState('');

  const [loadingFriends, setLoadingFriends] = useState(true);

  const [friends, setFriends] = useState<IFriendData[]>(null);

  const [friendsError, setFriendsError] = useState('');

  const [loadingFriendRequests, setLoadingFriendRequests] = useState(true);

  const [friendRequests, setFriendRequests] = useState<IFriendRequestsData>(null);

  const [friendRequestsError, setFriendRequestsError] = useState('');

  const [confirmCancelFriendId, setConfirmCancelFriendId] = useState(-1);

  const loadMyLists = async () => {
    setLoadingLists(true);
    setLists(null);
    await giftsApi
      .getLists()
      .then(data => {
        setLoadingLists(false);
        setListsError('');
        setLists(data.data);
      })
      .catch(error => {
        setLoadingLists(false);
        setListsError(error.response.data.error);
      });
  }

  const loadFriends = async () => {
    setLoadingFriends(true);
    setFriends(null);
    await giftsApi
      .getFriends()
      .then(data => {
        setLoadingFriends(false);
        setFriendsError('');
        setFriends(data.data);
      })
      .catch(error => {
        setLoadingFriends(false);
        setFriendsError(error.response.data.error);
      });
  }

  const loadPendingFriendRequests = async () => {
    setLoadingFriendRequests(true);
    setFriendRequests(null);
    await giftsApi
      .getFriendRequests()
      .then(data => {
        setLoadingFriendRequests(false);
        setFriendRequestsError('');
        setFriendRequests(data.data);
      })
      .catch(error => {
        setLoadingFriendRequests(false);
        setFriendRequestsError(error.response.data.error);
      });
  }

  useEffect(() => {
    loadMyLists();
    loadFriends();
    loadPendingFriendRequests();
  }, []);

  const handleCancelFriendRequest = async (id: number) => {
    setConfirmCancelFriendId(id);
  }

  const handleConfirmCancelFriendRequest = async (id: number) => {
    await giftsApi
      .cancelFriendRequest(id)
      .then(data => {
        setConfirmCancelFriendId(-1);
        loadPendingFriendRequests();
      })
      .catch(error => {
        setFriendRequestsError(error.response.data.error);
      });
  }

  const handleUnfriended = () => {
    loadFriends();
  }

  const handleFriendAccepted = () => {
    loadFriends();
  }

  return (
    <>
      {listsError && <ErrorAlert title='Cannot Get Your Gift Lists' message={listsError} />}
      {friendsError && <ErrorAlert title='Cannot Get Your Friends' message={friendsError} />}
      {friendRequestsError && <ErrorAlert title='Cannot Get Your Friend Requests' message={friendRequestsError} />}

      <TabList activeTabIndex={0}>

        <TabItem label="Lists">
          <h1>My Gift Lists</h1>
          <WideButton label='Create Gift List' navigatesTo='/new-list' />
          {loadingLists && <Spinner />}
          {lists?.length === 0 && <p>You have not created any gift lists!</p>}
          {lists?.map(l =>
            (l.visibility !== 'private' && l.visibility !== 'archived') && <GiftListSummary key={l.id} id={l.id} name={l.name} giftCount={l.giftCount} visibility={l.visibility} />
          )}
        </TabItem>

        {lists?.find(l => l.visibility === 'private') &&
          <TabItem label="Private Lists">
            <h1>My Private Gift Lists</h1>
            <WideButton label='Create Gift List' navigatesTo='/new-list' />
            {loadingLists && <Spinner />}
            {lists?.map(l =>
              (l.visibility === 'private') && <GiftListSummary key={l.id} id={l.id} name={l.name} giftCount={l.giftCount} visibility={l.visibility} />
            )}
          </TabItem>
        }

        {lists?.find(l => l.visibility === 'archived') &&
          <TabItem label="Archived Lists">
            <h1>My Archived Lists</h1>
            {loadingLists && <Spinner />}
            {lists?.map(l =>
              (l.visibility === 'archived') && <GiftListSummary key={l.id} id={l.id} name={l.name} giftCount={l.giftCount} visibility={l.visibility} />
            )}
          </TabItem>
        }

        <TabItem label="Friends">
          <h1>My Friends</h1>
          <WideButton label='Add Friend' navigatesTo='/friend-search' />
          {loadingFriends && <Spinner />}
          {friends?.length === 0 && <p>You do not have any friends!</p>}
          {friends?.map(f =>
            <FriendSummary key={f.id} id={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} lists={f.lists} onUnfriend={() => handleUnfriended()} />
          )}
        </TabItem>

        {friendRequests && (friendRequests?.forMe?.length > 0 || friendRequests?.forOthers?.length > 0) &&
          <TabItem label="Friend Requests">
            <h1>Friend Requests</h1>
            {friendRequests.forMe?.map(f =>
              <FriendRequest key={f.id} id={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} friendAccepted={() => handleFriendAccepted()} />
            )}
            {friendRequests.forOthers?.map(f =>
              <div className="my-friend-request">
                <p key={f.id}>
                  Awaiting friend request response from: <UserFullName firstName={f.firstName} lastName={f.lastName} />
                </p>
                <ActionButton label='Cancel' variant='red' onClick={() => handleCancelFriendRequest(f.id)} />
              </div>
            )}
          </TabItem>
        }
      </TabList>

      {confirmCancelFriendId !== -1 && <ConfirmDialog title="Cancel Friend Request" message={'Do you really want to cancel the friend request?'} onYesClick={() => handleConfirmCancelFriendRequest(confirmCancelFriendId)} onNoClick={() => setConfirmCancelFriendId(-1)} />}
    </>
  );
}

export default HomePage;
import { useState, useEffect, useCallback } from 'react';
import IConfig from '../IConfig';

let cachedConfig: IConfig = null;

let loadingPromise: Promise<IConfig> | null = null;

const fetchConfig = async () => {
    if (cachedConfig) {
        return cachedConfig;
    }

    if (!loadingPromise) {
        loadingPromise = fetch(`${process.env.PUBLIC_URL}/config.json`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch configuration.');
                }
                return response.json();
            })
            .then((config) => {
                cachedConfig = config;
                return cachedConfig;
            })
            .finally(() => {
                loadingPromise = null;
            });
    }

    return loadingPromise;
};

export const useConfig = () => {

    const [config, setConfig] = useState<IConfig>(null);

    const [error, setError] = useState<Error | null>(null);

    const loadConfig = useCallback(async () => {
        try {
            const loadedConfig = await fetchConfig();
            setConfig(loadedConfig);
        } catch (err) {
            setError(err as Error);
        }
    }, []);

    useEffect(() => {
        if (!config && !error) {
            loadConfig();
        }
    }, [config, error, loadConfig]);

    return { config, error, isLoading: !config && !error };
};
const sanitizeForId = (label: string) => {
    return label
        .toLowerCase()
        .replace(/[^\w\s]|(\s+)/g, (_match: string, group1: string) =>
            group1 ? "-" : ""
        );
};

const shortenUrl = (url: string) => {
    try {
        return new URL(url).hostname;
    } catch (error) {
        return url;
    }
}

export { sanitizeForId, shortenUrl };
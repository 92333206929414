import { useContext, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ExpandedUserFullName from '../../atoms/ExpandedUserFullName/ExpandedUserFullName';
import WideButton from '../../atoms/WideButton/WideButton';
import './FriendSearchResult.scss';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';

interface FriendSearchResultProps {
    userName: string;
    firstName: string;
    lastName: string;
    requestSent: () => void;
}

const FriendSearchResult = (props: FriendSearchResultProps) => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [madeRequest, setMadeRequest] = useState(false);

    const [error, setError] = useState('');

    const sendFriendRequest = async (userName: string) => {
        await giftsApi
            .createFriendRequest({ userName: userName })
            .then(data => {
                setError('');
                setMadeRequest(true);
                props.requestSent();
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    return (
        <div className="found-friend">
            <ExpandedUserFullName userName={props.userName} firstName={props.firstName} lastName={props.lastName} />

            {madeRequest
                ? <p className="found-friend-requested">You have sent a friend request to {props.userName}!</p>
                : <div className="found-friend-buttons">
                    <WideButton label='Send Friend Request' onClick={() => sendFriendRequest(props.userName)} />
                </div>
            }

            {error !== '' && <ErrorAlert title='Cannot Send Friend Request' message={error} />}
        </div>
    );
}

export default FriendSearchResult;
import { useContext, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import InfoBox from '../../atoms/InfoBox/InfoBox';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import Spinner from '../../atoms/Spinner/Spinner';
import WideButton from '../../atoms/WideButton/WideButton';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';

const ResetPasswordForm = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [changingPassword, setChangingPassword] = useState(false);

    const [userName, setUserName] = useState('');

    const [newPassword, setNewPassword] = useState('');

    const [changeCode, setChangeCode] = useState('');

    const [error, setError] = useState('');

    const [passwordChanged, setPasswordChanged] = useState(false);

    const handleChangePasswordButtonClick = async () => {
        setChangingPassword(true);
        setError('');
        await giftsApi
            .changePassword({
                userName: userName,
                newPassword: newPassword,
                changeCode: parseInt(changeCode)
            })
            .then(data => {
                setChangingPassword(false);
                setPasswordChanged(true);
            })
            .catch(error => {
                setChangingPassword(false);
                setError(error.response.data.error);
            });
    }

    return (
        <form method="post">

            {error !== '' && <ErrorAlert title='Cannot Change Password' message={error} />}

            {changingPassword
                ? <Spinner />
                :
                <>
                    {passwordChanged
                        ?
                        <>
                            <InfoBox message='Your password has been changed!' />
                            <WideButton label='Go home' navigatesTo='/' />
                        </>
                        :
                        <>
                            <LabelledInput name="userName" label="Username" placeholder="Enter Username" required={true} onChange={e => setUserName(e.target.value)} />
                            <LabelledInput name="newPassword" label="New Password" type="password" placeholder="Enter Password" required={true} onChange={e => setNewPassword(e.target.value)} />
                            <LabelledInput name="changeCode" label="Change Code" placeholder="Enter Change Code" required={true} onChange={e => setChangeCode(e.target.value)} />
                            <WideButton label='Change Password' submits={true} onClick={() => handleChangePasswordButtonClick()} />
                        </>
                    }
                </>
            }
        </form>
    )
}

export default ResetPasswordForm;
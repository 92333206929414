import { useContext, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ExpandedUserFullName from '../../atoms/ExpandedUserFullName/ExpandedUserFullName';
import GiftListSummary from '../GiftListSummary/GiftListSummary';
import './FriendSummary.scss';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { Visibility } from '../../../services/GiftsApi';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import DownChevron from '../../atoms/DownChevron/DownChevron';
import UpChevron from '../../atoms/UpChevron/UpChevron';

interface FriendSummaryProps {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    lists: Array<{ id: number; name: string; visibility: Visibility; giftCount: number }>;
    onUnfriend?: () => void
}

const FriendSummary = (props: FriendSummaryProps) => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [expanded, setExpanded] = useState(false);

    const [error, setError] = useState('');

    const [confirmUnfriend, setConfirmUnfriend] = useState(false);

    const handleUnfriendButtonClick = () => {
        setConfirmUnfriend(true);
    }

    const handleConfirmUnfriend = async () => {
        await giftsApi
            .removeFriend(props.id)
            .then(data => {
                if (props.onUnfriend) {
                    props.onUnfriend();
                }
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    const className = expanded ? 'friend-summary-expanded' : 'friend-summary-collapsed';

    return (
        <>
            <div className={`friend-summary ${className}`}>
                <div className="friend-summary-header" onClick={() => setExpanded(!expanded)}>
                    <ExpandedUserFullName userName={props.userName} firstName={props.firstName} lastName={props.lastName} />
                    {expanded ? <UpChevron /> : <DownChevron />}
                </div>
                <div className="friend-summary-content">
                    <div className="friend-summary-lists">
                        {props.lists.length === 0 && <p>{props.firstName} has no gift lists.</p>}
                        {props.lists?.map(l =>
                            <GiftListSummary key={l.id} id={l.id} name={l.name} visibility={l.visibility} giftCount={l.giftCount} />
                        )}
                    </div>
                    <div className="friend-summary-buttons">
                        <ActionButton label='Unfriend' variant='red' onClick={() => handleUnfriendButtonClick()} />
                    </div>
                </div>
            </div>
            {confirmUnfriend && <ConfirmDialog title="Unfriend" message={'Do you really want to unfriend ' + props.userName + '?'} onYesClick={() => handleConfirmUnfriend()} onNoClick={() => setConfirmUnfriend(false)} />}
            {error && <ErrorAlert title='Error' message={error} />}
        </>
    );
}

export default FriendSummary;
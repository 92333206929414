import { useState } from "react";
import LabelledComboBox from "../../atoms/LabelledComboBox/LabelledComboBox";
import Spinner from "../../atoms/Spinner/Spinner";
import FriendToggle from "../FriendToggle/FriendToggle";
import { Visibility, IFriendData } from "../../../services/GiftsApi";

interface ListVisibilitySelectorProps {
    visibility: Visibility;
    friends: IFriendData[];
    selectedFriends: string[];
    onChange: (visibility: Visibility, friends: string[]) => void;
}

const ListVisibilitySelector = (props: ListVisibilitySelectorProps) => {

    const [visibility, setVisibility] = useState<Visibility>(props.visibility);

    const [selectedFriends, setSelectedFriends] = useState<string[]>(props.selectedFriends);

    const handleVisibilityChanged = (visibility: Visibility) => {
        setVisibility(visibility);
        props.onChange(visibility, selectedFriends);
    }

    const handleFriendToggled = (userName: string, on: boolean) => {
        if (on) {
            selectedFriends.push(userName);
        } else {
            selectedFriends.splice(selectedFriends.indexOf(userName), 1);
        }
        setSelectedFriends(selectedFriends);
        props.onChange(visibility, selectedFriends);
    }

    return (
        <>
            <LabelledComboBox name="visibility" label="Visibility" value={visibility} onChange={e => handleVisibilityChanged(e.target.value as Visibility)} options={[
                { value: 'friends', label: 'All of my friends' },
                { value: 'specific', label: 'Specific friends' },
                { value: 'private', label: 'Private' }
            ]} />

            {visibility === 'specific' &&
                <>
                    {props.friends === null
                        ? <Spinner />
                        : props.friends.map(f =>
                            <FriendToggle key={f.id} id={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} checked={selectedFriends.indexOf(f.userName) !== -1} onChange={checked => handleFriendToggled(f.userName, checked)} />
                        )
                    }
                </>
            }
        </>
    );
}

export default ListVisibilitySelector;
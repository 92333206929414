import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackLink from '../../atoms/BackLink/BackLink';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import MinorButton from "../../atoms/MinorButton/MinorButton";
import WideButton from "../../atoms/WideButton/WideButton";
import StringParagraphs from "../../atoms/StringParagraphs/StringParagraphs";
import Spinner from "../../atoms/Spinner/Spinner";
import GiftLinkCollection from "../../organisms/GiftLinkCollection/GiftLinkCollection";
import GiftDetailsDialog from "../../organisms/GiftDetailsDialog/GiftDetailsDialog";
import UserName from "../../atoms/UserName/UserName";
import InfoBox from "../../atoms/InfoBox/InfoBox";
import ConfirmDialog from "../../organisms/ConfirmDialog/ConfirmDialog";
import useAccount from "../../../hooks/useAccount";
import CopyGiftDialog from "../../organisms/CopyGiftDialog/CopyGiftDialog";
import { useConfig } from '../../../hooks/useConfig';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import { IGiftData, IGiftLinkData } from '../../../services/GiftsApi';

const GiftPage = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const { config } = useConfig();

    const { listId, giftId } = useParams();

    const account = useAccount();

    const [loading, setLoading] = useState(true);

    const [gift, setGift] = useState<IGiftData>(null);

    const [giftError, setGiftError] = useState('');

    const [editingGift, setEditingGift] = useState(false);

    const [addingLink, setAddingLink] = useState(false);

    const [editingLink, setEditingLink] = useState(false);

    const [removingLink, setRemovingLink] = useState(false);

    const [confirmPurchase, setConfirmPurchase] = useState(false);

    const [confirmUnpurchase, setConfirmUnpurchase] = useState(false);

    const [confirmDeleteGift, setConfirmDeleteGift] = useState(false);

    const [copyingGift, setCopyingGift] = useState(false);

    const navigate = useNavigate();

    const loadGift = async () => {
        setGift(null);
        setGiftError('');
        await giftsApi
            .getGift(parseInt(listId), parseInt(giftId))
            .then(data => {
                setLoading(false);
                setGiftError('');
                setGift(data.data);
            })
            .catch(error => {
                setLoading(false);
                setGiftError(error.response.data.error);
            });
    }

    useEffect(() => {
        loadGift();
    }, []);

    const handleDeleteButtonClick = () => {
        setConfirmDeleteGift(true);
    }

    const handleConfirmDeleteGift = async () => {
        setGiftError('');
        await giftsApi
            .deleteGift(parseInt(listId), parseInt(giftId))
            .then(data => {
                navigate('/lists/' + parseInt(listId));
            })
            .catch(error => {
                setGiftError(error.response.data.error);
            });
    }

    const handleLinkAdded = async (link: IGiftLinkData) => {
        setAddingLink(true);
        setGiftError('');
        await giftsApi
            .createGiftLink(parseInt(listId), parseInt(giftId), link)
            .then(data => {
                setAddingLink(false);
                link.id = data.data.id;
            })
            .catch(error => {
                setAddingLink(false);
                setGiftError(error.response.data.error);
            });
    }

    const handleLinkEdited = async (link: IGiftLinkData) => {
        setEditingLink(true);
        setGiftError('');
        await giftsApi
            .editGiftLink(parseInt(listId), parseInt(giftId), link.id, link)
            .then(data => {
                setEditingLink(false);
                link.id = data.data.id;
            })
            .catch(error => {
                setEditingLink(false);
                setGiftError(error.response.data.error);
            });
    }

    const handleLinkRemoved = async (link: IGiftLinkData) => {
        setRemovingLink(true);
        await giftsApi
            .deleteGiftLink(parseInt(listId), parseInt(giftId), link.id)
            .then(data => {
                setRemovingLink(false);
                loadGift();
            })
            .catch(error => {
                setRemovingLink(false);
                setGiftError(error.response.data.error);
            });
    }

    const handleEditNameOrNotesButtonClick = () => {
        setEditingGift(true);
    }

    const handleGiftDetailsChanged = async (title: string, notes: string) => {
        setEditingGift(false);
        setGiftError('');
        await giftsApi
            .editGift(parseInt(listId), parseInt(giftId), { title: title, notes: notes })
            .then(data => {
                loadGift();
            })
            .catch(error => {
                setGiftError(error.response.data.error);
            });
    }

    const handleMarkAsPurchasedClick = () => {
        setConfirmPurchase(true);
    }

    const handleMarkAsUnpurchasedClick = () => {
        setConfirmUnpurchase(true);
    }

    const handleConfirmPurchase = async () => {
        setConfirmPurchase(false);
        await giftsApi
            .markAsPurchased(parseInt(listId), parseInt(giftId))
            .then(data => {
                loadGift();
            })
            .catch(error => {
                setGiftError(error.response.data.error);
            });
    }

    const handleConfirmUnpurchase = async () => {
        setConfirmUnpurchase(false);
        await giftsApi
            .markAsUnpurchased(parseInt(listId), parseInt(giftId))
            .then(data => {
                loadGift();
            })
            .catch(error => {
                setGiftError(error.response.data.error);
            });
    }

    return (
        <>
            <BackLink label={gift ? gift.listName : 'Back'} href={'/lists/' + listId} />

            {giftError && <ErrorAlert title='Cannot Load Gift' message={giftError} />}

            {loading && <Spinner />}

            {gift &&
                <>
                    <h1>{gift.title}</h1>

                    {config?.searchBrands &&
                        <p>
                            {config.searchBrands.map(b =>
                                <MinorButton key={b.label} variant="black" href={b.urlFormat.replace('$1', encodeURIComponent(gift.title))} label={"🔍 " + b.label} />
                            )}
                        </p>
                    }

                    {account.id !== gift.accountId &&
                        <>
                            <p>
                                <span>Gift requested by </span><UserName userName={gift.requestor.userName} showAvatar={true} />
                            </p>

                            {gift.purchases?.map(p =>
                                <p key={p.userName}>Purchased by <UserName userName={p.userName} showAvatar={true} /></p>
                            )}

                            {gift.purchases.find(p => p.userName === account.userName)
                                ?
                                <>
                                    <InfoBox message='You purchased this item.' />
                                    <WideButton label="Undo purchasing this item" variant="red" onClick={() => handleMarkAsUnpurchasedClick()} />
                                </>
                                :
                                <>
                                    <InfoBox message={'If you mark this gift as purchased, then everyone else will be able to see that it has been purchased. However, ' + gift.requestor.userName + ' will not see any change to the listing.'} />
                                    <WideButton label="Mark as purchased" onClick={() => handleMarkAsPurchasedClick()} />
                                </>
                            }
                        </>
                    }

                    {gift.notes &&
                        <>
                            <h2>Notes</h2>
                            <StringParagraphs text={gift.notes} />
                        </>
                    }

                    {account.id === gift.accountId &&
                        <>
                            <WideButton label="Edit Name or Notes" onClick={() => handleEditNameOrNotesButtonClick()} />
                            <WideButton label="Copy To Another List" onClick={() => setCopyingGift(true)} />
                        </>
                    }

                    {(gift?.links.length > 0 || account.id === gift.accountId) && <h2>Website Links</h2>}

                    <GiftLinkCollection
                        canManage={account.id === gift.accountId}
                        links={gift?.links}
                        linkAdded={link => handleLinkAdded(link)}
                        linkEdited={link => handleLinkEdited(link)}
                        linkRemoved={link => handleLinkRemoved(link)}
                    />

                    {(addingLink || editingLink || removingLink) && <Spinner />}

                    {account.id === gift.accountId && <WideButton label='Delete Gift Request' variant='red' onClick={() => handleDeleteButtonClick()} />}

                </>
            }

            {editingGift && <GiftDetailsDialog title={gift.title} notes={gift.notes} onSaved={(title, notes) => handleGiftDetailsChanged(title, notes)} onCancel={() => setEditingGift(false)} />}

            {confirmPurchase && <ConfirmDialog title="Confirm Purchase" message={'Are you sure you want to mark this gift as purchased by yourself? Everyone except for ' + (gift?.requestor?.userName ?? 'the requestor') + ' will see that you purchased it.'} onYesClick={() => handleConfirmPurchase()} onNoClick={() => setConfirmPurchase(false)} />}

            {confirmUnpurchase && <ConfirmDialog title="Confirm Not Purchased" message={'Are you sure you want to cancel your announcement that you purchased this gift? Your name will be removed from this gift.'} onYesClick={() => handleConfirmUnpurchase()} onNoClick={() => setConfirmUnpurchase(false)} />}

            {confirmDeleteGift && <ConfirmDialog title="Delete Gift" message={'Do you really want to delete this gift request?'} onYesClick={() => handleConfirmDeleteGift()} onNoClick={() => setConfirmDeleteGift(false)} />}

            {copyingGift && <CopyGiftDialog listId={parseInt(listId)} giftId={parseInt(giftId)} onCopied={() => setCopyingGift(false)} onCancel={() => setCopyingGift(false)} />}

        </>
    );
}

export default GiftPage;
import { useState } from "react";
import ActionButton from "../../atoms/ActionButton/ActionButton";
import GiftLink from "../../atoms/GiftLink/GiftLink";
import { IGiftLinkData } from "../../../services/GiftsApi";
import GiftLinkDialog from "../GiftLinkDialog/GiftLinkDialog";
import './GiftLinkCollection.scss';

interface GiftLinkCollectionProps {
    links: IGiftLinkData[];
    canManage: boolean;
    linkAdded?: (link: IGiftLinkData) => void;
    linkEdited?: (link: IGiftLinkData) => void;
    linkRemoved?: (link: IGiftLinkData) => void;
    linksChanged?: (links: IGiftLinkData[]) => void;
}

const GiftLinkCollection = (props: GiftLinkCollectionProps) => {

    const [addingLink, setAddingLink] = useState(false);

    const [editingLink, setEditingLink] = useState<IGiftLinkData>(null);

    const [links, setLinks] = useState(props.links);

    const handleAddLink = (url: string, title: string) => {
        setAddingLink(false);
        const newLink = { id: -1, pageUrl: url, pageTitle: title };
        const newLinks = [...links, newLink];
        setLinks(newLinks);
        if (props.linksChanged) {
            props.linksChanged(newLinks);
        }
        if (props.linkAdded) {
            props.linkAdded(newLink);
        }
    }

    const handleLinkEdited = (link: IGiftLinkData, url: string, title: string) => {
        setEditingLink(null);
        link.pageUrl = url;
        link.pageTitle = title;
        const newLinks = [...links];
        setLinks(newLinks);
        if (props.linksChanged) {
            props.linksChanged(newLinks);
        }
        if (props.linkEdited) {
            props.linkEdited(link);
        }
    }

    const handleRemoveLinkClick = (link: IGiftLinkData) => {
        const updatedLinks = links.filter(l => l !== link);
        setLinks(updatedLinks);
        if (props.linksChanged) {
            props.linksChanged(updatedLinks);
        }
        if (props.linkRemoved) {
            props.linkRemoved(link);
        }
    }

    return (
        <>
            {links.map(l =>
                <div key={l.id} className="gift-link">
                    <p>
                        <GiftLink url={l.pageUrl} title={l.pageTitle} shortenUrl={false} />
                    </p>
                    {props.canManage &&
                        <>
                            <ActionButton label="Edit" onClick={() => setEditingLink(l)} />
                            <ActionButton label="Delete" variant="red" onClick={() => handleRemoveLinkClick(l)} />
                        </>
                    }
                </div>
            )}

            {props.canManage && <ActionButton label='Add Link' onClick={() => setAddingLink(true)} />}

            {addingLink && <GiftLinkDialog onSaved={(url, title) => handleAddLink(url, title)} onCancel={() => setAddingLink(false)} />}
            {editingLink && <GiftLinkDialog url={editingLink.pageUrl} title={editingLink.pageTitle} onSaved={(url, title) => handleLinkEdited(editingLink, url, title)} onCancel={() => setEditingLink(null)} />}
        </>
    );
}

export default GiftLinkCollection;
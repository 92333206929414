import { useContext, useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LabelledInput from '../../atoms/LabelledInput/LabelledInput';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import WideButton from '../../atoms/WideButton/WideButton';
import FriendSearchResult from '../../organisms/FriendSearchResult/FriendSearchResult';
import BackLink from '../../atoms/BackLink/BackLink';
import { useNavigate } from 'react-router-dom';
import GlobalVariablesContext from '../../contexts/GlobalVariablesContext';
import { IAccountData } from '../../../services/GiftsApi';

const FriendSearchPage = () => {

    const { giftsApi } = useContext(GlobalVariablesContext);

    const [term, setTerm] = useState('');

    const [friends, setFriends] = useState<IAccountData[]>([]);

    const [friendAdded, setFriendAdded] = useState(false);

    const [error, setError] = useState('');

    const handleSearchButtonClick = async () => {
        await giftsApi
            .searchForAccounts(term)
            .then(data => {
                setError('');
                setFriends(data.data);
                if (data.data.length === 0) {
                    setError('No friends found matching the specified text!');
                }
            })
            .catch(error => {
                setError(error.response.data.error);
            });
    }

    const handleReloadButtonClick = () => {
        setFriends([]);
        setError('');
    }

    const handleRequestSent = () => {
        setFriendAdded(true);
    }

    const navigate = useNavigate();

    const handleSearchAgainButtonClick = () => {
        setFriendAdded(false);
        setTerm('');
        setFriends([]);
    }

    const handleGoHomeButtonClick = () => {
        navigate('/home');
    }

    return (
        <>

            {friends.length === 0 && <BackLink />}
            {friends.length > 0 && <BackLink onClick={() => handleReloadButtonClick()} label='Find Friends' />}

            <h2>Find Friend</h2>

            {friends.length === 0 && <>
                <LabelledInput name="term" label="Friend Username, First Name or Last Name" placeholder="Enter Name" required={true} onChange={e => setTerm(e.target.value)} />
                <WideButton label='Search' onClick={() => handleSearchButtonClick()} />
            </>}

            {error !== '' && <ErrorAlert title='Cannot Find Friends' message={error} />}

            {friends.map(f =>
                <FriendSearchResult key={f.id} userName={f.userName} firstName={f.firstName} lastName={f.lastName} requestSent={() => handleRequestSent()} />
            )}

            {friendAdded &&
                <>
                    <WideButton label='Search for another friend' onClick={() => handleSearchAgainButtonClick()} />
                    <WideButton label='Return home' onClick={() => handleGoHomeButtonClick()} />
                </>
            }
            {!friendAdded && <LinkBox href="/home" label="Cancel" />}
        </>
    );
}

export default FriendSearchPage;
import { Link } from 'react-router-dom';
import StringParagraphs from '../../atoms/StringParagraphs/StringParagraphs';
import GiftLink from '../../atoms/GiftLink/GiftLink';
import './Gift.scss';
import InfoBox from '../../atoms/InfoBox/InfoBox';
import { IGiftLinkData, IGiftPurchaseData } from '../../../services/GiftsApi';

interface GiftProps {
    listId: number;
    giftId: number;
    title: string;
    notes: string;
    links: IGiftLinkData[];
    purchases: IGiftPurchaseData[];
    navigable: boolean;
}

const Gift = (props: GiftProps) => {
    const navigableClassName = props.navigable ? 'gift-navigable' : 'gift-not-navigable';
    const purchasedClassName = props.purchases?.length > 0 ? 'gift-purchased' : 'gift-not-purchased';
    return (
        <div className={`gift ${navigableClassName} ${purchasedClassName}`}>

            {props.navigable &&
                <Link to={'/lists/' + encodeURIComponent(props.listId) + '/gifts/' + encodeURIComponent(props.giftId)}>
                    <h2 className="gift-title">{props.title}</h2>
                </Link>
            }
            {!props.navigable &&
                <h2 className="gift-title">{props.title}</h2>
            }

            {props.notes && props.notes !== '' && <StringParagraphs text={props.notes} />}

            {props.links &&
                <table className="gift-links-table">
                    <tbody>
                        {props.links.map(l =>
                            <tr key={l.id}>
                                <td>
                                    <p className="gift-link-icon"><a href={l.pageUrl} target="_blank" rel="noreferrer">🔗</a></p>
                                </td>
                                <td>
                                    <GiftLink url={l.pageUrl} title={l.pageTitle} shortenUrl={true} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }

            {props.purchases?.map(p =>
                <InfoBox key={p.id} message={'Purchased by ' + p.userName} />
            )}
        </div>
    );
}

export default Gift;
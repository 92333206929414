import ActionButton from '../../atoms/ActionButton/ActionButton';
import Dialog from '../Dialog/Dialog';
import "./ConfirmDialog.scss";

interface ConfirmDialogProps {
    title: string;
    message: string;
    onYesClick?: () => void;
    onNoClick?: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {

    const handleYesClicked = () => {
        props.onYesClick?.();
    }

    const handleNoClicked = () => {
        props.onNoClick?.();
    }

    return (
        <Dialog className="confirm-dialog">
            <h2 className="confirm-dialog-title">{props.title}</h2>
            <p className="confirm-dialog-message">{props.message}</p>
            <ActionButton label='Yes' onClick={() => handleYesClicked()} />
            <ActionButton label='No' variant='red' onClick={() => handleNoClicked()} />
        </Dialog>
    );
};

export default ConfirmDialog;

